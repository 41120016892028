<template>
  <Form @submit="save">
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbs" class="ma-0 pa-0" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h1>{{ this.uuid ? 'Editar' : 'Agregar' }} vehículo</h1>
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <SelectField
          v-model="form.make_id"
          :items="makes"
          item-text="name"
          item-value="id"
          label="Marca *"
          prepend-icon="mdi-car"
          rules="required"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <SelectField
          v-model="form.model_id"
          :items="models"
          item-text="name"
          item-value="id"
          label="Modelo *"
          prepend-icon="mdi-car"
          rules="required"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <SelectField
          v-model="form.type_id"
          :items="types"
          item-text="name"
          item-value="id"
          label="Tipo *"
          prepend-icon="mdi-car"
          rules="required"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="form.motor"
          label="Motor *"
          prepend-icon="mdi-format-text"
          rules="required"
        />
      </v-col>

      <v-col cols="12" class="text-center">
        <v-btn color="primary" type="submit">
          <v-icon left>mdi-content-save</v-icon>
          Guardar
        </v-btn>
      </v-col>
    </v-row>
  </Form>
</template>

<script>
// API
import VehicleAPI from '@/api/vehicle';

export default {
  name: 'VehicleForm',

  props: {
    uuid: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    form: {
      make_id: null,
      model_id: null,
      motor: null,
      type_id: null,
    },
    makes: [],
    models: [],
    types: [],
  }),

  async created() {
    this.api = new VehicleAPI();
    await this.getMakes();
    await this.getTypes();

    if (this.uuid) {
      await this.detail();
    }
  },

  computed: {
    breadcrumbs() {
      const items = [
        {
          text: 'Vehículos',
          disabled: false,
          exact: true,
          to: { name: 'vehicle-list' },
        },
      ];

      if (this.uuid) {
        const name = this.form.model
          ? `${this.form.model.make.name} ${this.form.model.name}`
          : '';
        items.push(
          {
            text: name.toUpperCase(),
            disabled: false,
            exact: true,
            to: { name: 'vehicle-detail', params: { uuid: this.uuid } },
          },
          {
            text: 'Editar',
            disabled: true,
          },
        );
      } else {
        items.push({
          text: 'Agregar',
          disabled: true,
        });
      }

      return items;
    },
  },

  watch: {
    /* eslint-disable object-shorthand */
    async 'form.make_id'() {
      await this.getModels();
    },
  },

  methods: {
    async save() {
      this.$store.commit('showLoader');
      try {
        if (this.uuid) {
          await this.api.update(this.uuid, this.form);
          this.$router.push({ name: 'vehicle-detail', params: { uuid: this.uuid } });
        } else {
          await this.api.create(this.form);
          this.$router.push({ name: 'vehicle-list' });
        }

        this.$store.dispatch('notification/notifySuccess');
      } catch (error) {
        this.$store.dispatch('notification/notifyError', 'No se pudieron guardar los datos.');
      }
      this.$store.commit('hideLoader');
    },

    async detail() {
      this.$store.commit('showLoader');
      try {
        this.form = await this.api.detail(this.uuid);
        this.form.make_id = this.form.model.make.id;
        this.form.model_id = this.form.model.id;
        this.form.type_id = this.form.type.id;
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async getMakes() {
      this.$store.commit('showLoader');
      try {
        const res = await this.api.makes();
        this.makes = res.results;
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async getModels() {
      this.$store.commit('showLoader');
      try {
        const res = await this.api.models({ make_id: this.form.make_id });
        this.models = res.results;
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async getTypes() {
      this.$store.commit('showLoader');
      try {
        const res = await this.api.types();
        this.types = res.results;
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="sass" scoped>

</style>
